<template>
  <Layout>
    <template
      v-if="!hideLabel"
      v-slot:label
    >
      {{ row.label }}
    </template>

    <template
      v-if="!hideLabel"
      v-slot:extra
    >
      {{ row.extra }}
    </template>

    <template v-slot:input>
      <div class="flex items-center">
        <input
          v-model="model"
          type="checkbox"
          class="flex-auto text-lg text-purple-500 form-checkbox mt-1 mr-2"
        >
        <div class="flex-auto">
          <input
            type="file"
            class="btn btn-xs"
            :class="[ isInverted ? 'btn-dark-inverted' : 'btn-dark', row.class]"
            @change="onChange"
          >
        </div>
      </div>
    </template>
  </Layout>
</template>
<script>
const Layout = () => import('./Layout')

export default {
  name: 'MapInput',

  components: {
    Layout
  },

  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      model: this.row.value
    }
  },

  computed: {

    isInverted () {
      if (this.row.hasOwnProperty('inverted')) {
        return (this.row.inverted === true)
      }
      return false
    },

    hideLabel () {
      return (this.row.fullwidth && this.row.fullwidth)
    },

    hasMultipleOptions () {
      return (this.row.options)
    },
    isSimple () {
      return (this.row.simple && this.row.simple === true)
    }
  }
}

</script>
